<template>
    <div :id="$weScore.element_id" class="we-score-form">
        <template v-if="result">
            <vue-markdown v-if="form.text_after" class="ws-markdown">
                {{ form.text_after }}
            </vue-markdown>
            <p
                v-else
                v-text="
                    $trans(
                        'Bedankt voor de inschrijving.',
                        'after_submit.fallback.thanks_for_registering'
                    )
                "
            ></p>

            <PaymentDisplay :result="result" :form="form" />

            <h3
                v-text="
                    $trans(
                        'Gegevens ter controle',
                        'after_submit.title.data_for_confirmation'
                    )
                "
            ></h3>

            <ResultDisplay
                :result="result"
                :form="form"
                :pupil-custom-fields="pupilCustomFields"
                :customer-custom-fields="customerCustomFields"
                :registration-custom-fields="registrationCustomFields"
                :financial-custom-fields="financialCustomFields"
            />
        </template>
        <form v-else-if="form" @submit.prevent="submit">
            <h2 v-if="form.settings.show_title" v-text="form.name"></h2>

            <vue-markdown v-if="form.text_before" class="ws-markdown"
                >{{ form.text_before }}
            </vue-markdown>

            <h3 v-text="pupilFormTitle"></h3>

            <PupilForm
                v-model="pupil"
                :form="form"
                :errors="errors"
                :show-middle-name="showMiddleName"
                :min-age="minAge"
                :max-age="maxAge"
                class="ws-pupil-form"
            />

            <CustomFields
                v-if="pupilCustomFields.length"
                :values="customFieldsValues"
                :fields="pupilCustomFields"
                :errors="customFieldsErrors"
                :class="$css('ws-mt-4')"
                class="ws-pupil-extra"
                @input="updateCustomFieldValue"
            />

            <h3 v-text="locationTitle"></h3>

            <RegistrationForm
                v-model="pupil.registration"
                :form="form"
                :location-relation="locationRelation"
                :location-settings="locationSettings"
                :errors="errors"
                class="ws-registration-form"
            />

            <CustomFields
                v-if="registrationCustomFields.length"
                :values="customFieldsValues"
                :fields="registrationCustomFields"
                :errors="customFieldsErrors"
                :class="$css('ws-mt-4')"
                class="ws-registration-extra"
                @input="updateCustomFieldValue"
            />

            <h3 v-text="customerTitle"></h3>

            <CustomerForm
                v-model="customer"
                :errors="errors"
                :show-middle-name="showMiddleName"
                :existing-customer="existingCustomer"
                class="ws-customer-form"
            />

            <CustomFields
                v-if="customerCustomFields.length"
                :values="customFieldsValues"
                :fields="customerCustomFields"
                :errors="customFieldsErrors"
                :class="$css('ws-mt-4')"
                class="ws-customer-extra"
                @input="updateCustomFieldValue"
            />

            <h3 v-text="financialTitle"></h3>

            <FinancialForm
                v-model="customer"
                :form="form"
                :curriculum-relation="curriculumRelation"
                :subscription-relation="subscriptionRelation"
                :subscription-id="subscription_id"
                :errors="errors"
                :existing-customer="existingCustomer"
                class="ws-financial-form"
                @input:subscription_id="subscription_id = $event"
            />

            <CustomFields
                v-if="financialCustomFields.length"
                :values="customFieldsValues"
                :fields="financialCustomFields"
                :errors="customFieldsErrors"
                :class="$css('ws-mt-4')"
                class="ws-financial-extra"
                @input="updateCustomFieldValue"
            />

            <div
                v-if="extraFinancialText"
                :class="$css('ws-mt-4')"
                class="ws-financial-extra-text"
                v-html="extraFinancialText"
            ></div>

            <AgreeForm
                v-model="customer"
                :errors="errors"
                :form="form"
                :class="$css('ws-mt-4')"
                class="ws-agree-form"
            />

            <p :class="$css('ws-text-md-right')">
                <small
                    v-if="error"
                    :class="$css('ws-text-danger')"
                    style="margin-right: 10px"
                    >{{ error }}</small
                >
                <button
                    type="submit"
                    :class="$css('ws-button')"
                    :disabled="submitting"
                    v-text="buttonText"
                ></button>
            </p>
        </form>
        <small
            v-else-if="error"
            :class="$css('ws-text-danger')"
            v-text="error"
        ></small>
        <small
            v-else
            v-text="$trans('Formulier wordt geladen...', 'form.loading_form')"
        ></small>
    </div>
</template>

<script>
import omit from 'lodash/omit';
import sortBy from 'lodash/sortBy';
import VueMarkdown from '@adapttive/vue-markdown';
import PupilForm from '@/components/PupilForm.vue';
import CustomerForm from '@/components/CustomerForm.vue';
import CustomFields from '@/components/CustomFields.vue';
import RegistrationForm from '@/components/RegistrationForm.vue';
import FinancialForm from '@/components/FinancialForm.vue';
import AgreeForm from '@/components/AgreeForm.vue';
import ResultDisplay from '@/components/ResultDisplay.vue';
import PaymentDisplay from '@/components/PaymentDisplay.vue';
import BirthDateValidator from '@/mixins/BirthDateValidator';

import {
    PREPARE,
    EMAIL_EXISTS,
    ADD_CUSTOMER_PUPIL,
    CREATE_CUSTOMER,
} from '@/graphql';
import {
    curriculumRelation,
    findFirstValidCourseId,
    findFirstValidCurriculumId,
    locationRelation,
    subscriptionRelation,
} from '@/OptionRelationsHelper';

const EMAIL_REGEX = /^([a-zA-Z0-9_\-.]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,63})$/;

export default {
    name: 'App',

    components: {
        ResultDisplay,
        PaymentDisplay,
        PupilForm,
        CustomerForm,
        CustomFields,
        RegistrationForm,
        FinancialForm,
        AgreeForm,
        VueMarkdown,
    },

    mixins: [BirthDateValidator],

    data: () => ({
        showMiddleName: true,
        emailExists: false,
        minAge: { years: null, months: null },
        maxAge: { years: null, months: null },
        submitting: false,
        form: null,
        result: null,
        error: null,
        errors: {},
        subscription_id: '', //needs to be separate, to prevent update loops on options change
        input: {
            first_name: '',
            middle_name: '',
            last_name: '',
            email: '',
            street: '',
            zipcode: '',
            house_number: '',
            city: '',
            country_code: 'NL',
            payment_method: '',
            subscription_id: '',
            account_iban: '',
            account_name: '',
            phone_numbers: [
                {
                    number: '',
                    ordering: 0,
                },
            ],
            terms_agreed: false,
            privacy_agreed: false,
            pupils: [
                {
                    first_name: '',
                    middle_name: '',
                    last_name: '',
                    birth_date: '',
                    gender: '',
                    registration: {
                        notes: '',
                        start_date: '',
                        curriculum_id: '',
                        course_id: '',
                        location_id: '',
                        pupilStartLessonSlots: { current: [] },
                    },
                    customFieldValues: [],
                },
            ],
            customFieldValues: [],
        },
    }),

    apollo: {
        form: {
            query: PREPARE,
            result() {
                if (this.form) {
                    //set default values
                    const location_id = this.form.locations[0].id;
                    const curriculum_id = findFirstValidCurriculumId(
                        this.form,
                        location_id
                    );
                    this.input.pupils[0].registration = {
                        ...this.input.pupils[0].registration,
                        location_id,
                        curriculum_id,
                        course_id: findFirstValidCourseId(
                            this.form,
                            curriculum_id
                        ),
                    };
                    this.setCustomFieldValues();
                    if (window.weScoreDataLoaded) {
                        return window.weScoreDataLoaded.call(this);
                    }
                    if (this.form.settings.adult_audience) {
                        this.$watch('pupil.first_name', value =>
                            this.syncCustomerNamePart('first_name', value)
                        );
                        this.$watch('pupil.middle_name', value =>
                            this.syncCustomerNamePart('middle_name', value)
                        );
                        this.$watch('pupil.last_name', value =>
                            this.syncCustomerNamePart('last_name', value)
                        );
                    }
                }
                this.error = null;
            },
            error() {
                this.error = this.$trans(
                    'Fout in verbinding naar de server',
                    'error.connection_error'
                );
            },
        },
        emailExists: {
            query: EMAIL_EXISTS,
            variables() {
                return { email: this.checkEmailAddress };
            },
            skip() {
                return !this.checkEmailAddress;
            },
            debounce: 500,
        },
    },

    computed: {
        pupilFormTitle() {
            return this.form.settings.adult_audience
                ? this.$trans(
                      'Gegevens cursist',
                      'general.title.pupil_data_adult'
                  )
                : this.$trans(
                      'Gegevens leerling',
                      'general.title.pupil_data_child'
                  );
        },
        locationTitle() {
            return this.$trans(
                'Locatie en les',
                'general.title.location_and_lesson'
            );
        },
        customerTitle() {
            return this.form.settings.adult_audience
                ? this.$trans(
                      'Klantgegevens',
                      'general.title.customer_data_adult'
                  )
                : this.$trans(
                      'Gegevens ouder/verzorger',
                      'general.title.customer_data_child'
                  );
        },
        financialTitle() {
            return this.$trans('Financieel', 'general.title.financial');
        },
        buttonText() {
            return this.$trans('Versturen', 'general.button.submit');
        },
        pupil: {
            get() {
                return this.input.pupils[0];
            },
            set(pupil) {
                this.input.pupils = [pupil];
            },
        },
        customer: {
            get() {
                return this.input;
            },
            set(customer) {
                this.input = { ...customer, pupils: this.input.pupils };
            },
        },
        formFields() {
            return this.form.fields.filter(field => !!field.customField);
        },
        pupilCustomFields() {
            return sortBy(
                this.formFields.filter(field => {
                    return (
                        field.form_position === 'fieldable' &&
                        field.type === 'pupil' &&
                        this.filterCustomField('pupil', field, this.input)
                    );
                }),
                'ordering'
            );
        },
        customerCustomFields() {
            return sortBy(
                this.formFields.filter(field => {
                    return (
                        field.form_position === 'fieldable' &&
                        field.type === 'customer' &&
                        this.filterCustomField('customer', field, this.input)
                    );
                }),
                'ordering'
            );
        },
        registrationCustomFields() {
            return sortBy(
                this.formFields.filter(field => {
                    return (
                        field.form_position === 'registration' &&
                        this.filterCustomField(
                            'registration',
                            field,
                            this.input
                        )
                    );
                }),
                'ordering'
            );
        },
        financialCustomFields() {
            return sortBy(
                this.formFields.filter(field => {
                    return (
                        field.form_position === 'financial' &&
                        this.filterCustomField('financial', field, this.input)
                    );
                }),
                'ordering'
            );
        },
        customFieldsValues() {
            const values = {};
            this.pupil.customFieldValues.forEach(fieldValue => {
                values[`field_${fieldValue.custom_field_id}`] =
                    fieldValue.value;
            });
            this.customer.customFieldValues.forEach(fieldValue => {
                values[`field_${fieldValue.custom_field_id}`] =
                    fieldValue.value;
            });
            return values;
        },
        invalidFieldNames() {
            return Object.entries(this.errors).map(([errorKey]) => {
                return errorKey;
            });
        },
        customFieldsErrors() {
            const customFieldsErrors = {};
            const indexLookup = /(pupils\.0\.)?customFieldValues\.(\d)\./;
            Object.entries(this.errors)
                .filter(([errorKey]) => {
                    return errorKey.includes('customFieldValues');
                })
                .forEach(([errorKey, errors]) => {
                    let m = indexLookup.exec(errorKey);
                    if (m) {
                        const type = m[1] ? 'pupil' : 'customer';
                        const index = m[2];
                        const custom_field_id =
                            this[type].customFieldValues[index]
                                ?.custom_field_id;
                        customFieldsErrors[`field_${custom_field_id}`] = errors;
                    }
                });
            return customFieldsErrors;
        },
        locationSettings() {
            return this.form.location_settings.find(
                s => s.location_id === this.pupil.registration.location_id
            );
        },
        locationRelation() {
            return locationRelation(
                this.form,
                this.pupil.registration.location_id
            );
        },
        curriculumRelation() {
            return curriculumRelation(
                this.form,
                this.pupil.registration.location_id,
                this.pupil.registration.curriculum_id
            );
        },
        subscriptionRelation() {
            return subscriptionRelation(
                this.form,
                this.pupil.registration.location_id,
                this.subscription_id
            );
        },
        existingCustomer() {
            return this.checkEmailAddress && this.emailExists;
        },
        checkEmailAddress() {
            if (this.input.email.match(EMAIL_REGEX)) {
                return this.input.email;
            }
            return null;
        },
        extraFinancialText() {
            if (window.weScoreExtraFinancialText) {
                return window.weScoreExtraFinancialText.call(this);
            }
            return '';
        },
    },

    mounted() {
        this.$el.dispatchEvent(new Event('mounted'));
    },

    methods: {
        async submit() {
            this.errors = {};
            this.error = null;
            if (!this.validateCustomFields() || !this.validateBirthDate()) {
                this.error = this.$trans(
                    'Herstel aub de fouten in het formulier',
                    'error.invalid_form_fields'
                );
                this.$nextTick(this.showInvalidField);
                return;
            }
            this.submitting = true;
            const input = {
                ...(this.existingCustomer
                    ? this.existingCustomerInput(this.input)
                    : this.input),
                pupils: [
                    {
                        ...this.input.pupils[0],
                        registration: {
                            ...this.input.pupils[0].registration,
                            pupilStartLessonSlots: omit(
                                this.input.pupils[0].registration
                                    .pupilStartLessonSlots,
                                ['current']
                            ),
                        },
                    },
                ],
                subscription_id: this.subscription_id,
            };
            try {
                await this.$apollo.mutate({
                    mutation: this.existingCustomer
                        ? ADD_CUSTOMER_PUPIL
                        : CREATE_CUSTOMER,
                    variables: { input },
                    update: (store, { data: { createCustomer } }) => {
                        this.result = createCustomer;
                        this.submitting = false;
                        this.$nextTick(() => this.$el.scrollIntoView(true));
                    },
                });
            } catch (error) {
                const { message, validationErrors } =
                    this.handleGraphQlError(error);
                this.errors = validationErrors;
                //scroll to first invalid field
                this.$nextTick(this.showInvalidField);
                this.error = message;
                this.submitting = false;
            }
        },
        showInvalidField() {
            const firstInvalid = document.querySelector('[data-is-invalid]');
            if (firstInvalid) {
                firstInvalid.scrollIntoView(true);
            }
        },
        syncCustomerNamePart(part, value) {
            if (
                !this.input[part] || // empty
                value.startsWith(this.input[part]) || //adding to existing string
                (value.length < this.input[part].length &&
                    this.input[part].startsWith(value)) //using backspace on same string
            ) {
                this.input[part] = value;
            }
        },
        filterCustomField(type, field) {
            if (window.weScoreFilterCustomField) {
                return window.weScoreFilterCustomField.call(this, type, field);
            }
            return true;
        },
        setCustomFieldValues() {
            this.pupil.customFieldValues = [];
            this.customer.customFieldValues = [];
            this.formFields.forEach(field => {
                this[field.type].customFieldValues.push({
                    custom_field_id: field.custom_field_id,
                    value: field.customField.default_value,
                });
            });
        },
        updateCustomFieldValue({ type, custom_field_id }, value) {
            const customFieldValue = {
                custom_field_id,
                value,
            };
            const index = this[type].customFieldValues.findIndex(
                cfv => cfv.custom_field_id === customFieldValue.custom_field_id
            );
            if (index === -1) {
                this[type].customFieldValues.push(customFieldValue);
            } else {
                this[type].customFieldValues.splice(index, 1, customFieldValue);
            }
        },
        validateBirthDate() {
            const error = this.birthDateValidationError();
            if (error) {
                this.errors = {
                    ...this.errors,
                    'pupils.0.birth_date': [error],
                };
                return false;
            }
            return true;
        },
        validateCustomFields() {
            let hasErrors = false;
            [
                ['pupil', this.pupil.customFieldValues],
                ['customer', this.customer.customFieldValues],
            ].forEach(([type, field_values]) => {
                field_values.forEach((field_value, index) => {
                    const field = this.formFields.find(
                        field =>
                            field.custom_field_id ===
                            field_value.custom_field_id
                    );
                    let key =
                        type === 'pupil'
                            ? 'pupils.0.customFieldValues'
                            : 'customFieldValues';
                    if (
                        field.required &&
                        (!field_value.value ||
                            !field_value.value.length ||
                            !field_value.value[0])
                    ) {
                        this.errors[`${key}.${index}.value.0`] = [
                            this.$trans(
                                `Veld %label% is verplicht`,
                                'error.field_required',
                                { label: field.customField.label }
                            ),
                        ];
                        hasErrors = true;
                    }
                    if (window.weScoreValidateCustomField) {
                        const errors = window.weScoreValidateCustomField.call(
                            this,
                            field,
                            field_value
                        );
                        if (errors.length > 0) {
                            hasErrors = true;
                            this.errors[`${key}.${index}.value.0`] = [
                                ...(this.errors[`${key}.${index}.value.0`] ||
                                    []),
                                ...errors,
                            ];
                        }
                    }
                });
            });
            return !hasErrors;
        },
        existingCustomerInput(input) {
            return omit(input, [
                'first_name',
                'middle_name',
                'last_name',
                'street',
                'house_number',
                'zipcode',
                'city',
                'country_code',
                'payment_method',
                'account_name',
                'account_iban',
                'phone_numbers',
                'customFieldValues',
            ]);
        },
        handleGraphQlError(error) {
            let message,
                details = '',
                validationErrors = {};
            if (!error.graphQLErrors && !error.networkError) {
                details = error.message;
                message = this.$trans(
                    'Fout in verwerking verzoek',
                    'error.general_error'
                );
                return { details, message, validationErrors };
            }
            if (error.networkError) {
                details = error.message;
                message = this.$trans(
                    'Fout in verbinding naar de server',
                    'error.connection_error'
                );
            } else {
                const gqlError = error.graphQLErrors?.[0];
                message =
                    gqlError?.message ||
                    error.message ||
                    this.$trans(
                        'Fout in gegevensverwerking',
                        'error.server_error'
                    );
                if (gqlError.extensions?.reason) {
                    details = gqlError.extensions.reason;
                }
                if (gqlError.extensions?.validation) {
                    message = this.$trans(
                        'Herstel aub de fouten in het formulier',
                        'error.invalid_form_fields'
                    );
                    validationErrors = this.translateErrors(
                        gqlError.extensions.validation
                    );
                }
            }
            return { details, message, validationErrors };
        },
        translateErrors(formErrors) {
            const validationErrors = {};
            Object.entries(formErrors).forEach(([key, errors]) => {
                validationErrors[key.replace('input.', '')] = errors.map(err =>
                    this.$trans(`error.${err}`)
                );
            });
            return validationErrors;
        },
    },
};
</script>
